import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { teal } from '@mui/material/colors';

import { PageHero } from '../../component/page';

import ImgLeadership from '../../../img/about/leadership-team/management/LeadershipBG.png';

// Page Imports
import _1 from './leadership-team/_1';
import _2 from './leadership-team/_2';

const template = () => {
  return (
    <>
    <Box>
    <Box style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "55px 0 0 0", 
      overflow: "hidden",

      }}>
        <img
          src={`${ImgLeadership}`}
          alt=""
          style={{ 
            maxWidth: "100%", 
            maxHeight: "100%", 
            objectFit: "contain" }}
        />
        </Box>
        </Box>
      <Box sx={{ pt: 1 }}>
      <Box
        sx={{ py: 3 }}
        style={{
            backgroundColor: teal[50]
        }}>
        <Container>
          <Typography variant="h1" component="h1">
            Management
          </Typography>
        </Container>
      </Box>
      </Box>
      <Container sx={{ pt: 5, pb: 10 }}>
        <_1 />
      </Container>
      <Box
        sx={{ py: 5 }}
        style={{
            backgroundColor: teal[50]
        }}>
        <Container>
          <Typography variant="h1" component="h1">
            Advisory
          </Typography>
        </Container>
      </Box>
      <Container sx={{ pt: 5, pb: 10 }}>
        <_2 />
      </Container>
    </>
  );
}

export default template;