import React from 'react';
import { Box, Grid } from '@mui/material';

import Imgbackground from '../../../../img/product/_0/product-offerings.png';

const template = () => {
  return (
    <>
    <Box>
    <Box style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "50px 0 0 0",
      overflow: "hidden",
 
      }}>
  <img
    src={`${Imgbackground}`}
    alt=""
    style={{
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "contain" }}
  />
  </Box>
  <Box sx={{
          position: 'absolute',
          top: { xs: '60px', sm: '60px', md: '70px' },
          left: { xs: '5px', sm: '15px', md: '20px' },
          right: 0,
          bottom: 0,
          display: 'flex',
          color: '#FFF',
          textAlign: 'topleft',
          fontSize: { xs: '17px', sm: '40px', md: '50px' }, // Responsive font size
          fontWeight: 400,
          // fontFamily: 'Segoe UI',
        }}>
      <Box sx={{display:"flex", gap: '5px', marginLeft: '20px'}}>
        <Box sx={{fontSize:{ xs: '10px', sm: '30px', md: '30px' }, fontWeight: 'light', pt:{xs:1, md:2.5}}}>
          Products
        </Box>
        <Box sx={{fontWeight:'light'}}>
          Overview
        </Box>
      </Box>
  </Box>
  </Box>
 
    </>
  );
}
 
export default template;