import React from 'react';
import { Typography,Box } from '@mui/material';
import ImgPartners from '../../../img/partnerships/partners.png';


const template = () => {
  return (
    <>

    <box style={{
        // border:"2px solid red", //To visualize the box
        display: "flex", 
        flexDirection:"column",
        alignItems: "center", 
        justifyContent: "center",
        height:"200px"
    }}>
        {/* Box for the header */}
        <box style={{
            // border:"2px solid blue", //To visualize the box
            marginTop:'0px',
            color: "#042D43",
            fontWeight: 400,
            fontFamily: 'Segoe UI',
            fontSize: '60px',
            lineHeight: '24px',
            textAlign: "justify"
        }}>
        Industry Consortium Partners
        </box>

        {/* Box for the content */}
        <box style={{
            // border:"2px solid red", //To visualize the box
            marginTop:'30px',
            color: "#042D43",
            // fontWeight: 400,
            fontFamily: 'Segoe UI',
            fontSize: '15px',
            lineHeight: '24px',
            textAlign: "justify",
            width: "70%"
        }}>
            
            Our Industry Consortium partners are at the forefront of driving innovation and shaping the future of technology across diverse sectors. By collaborating with leading organizations, we foster a shared vision to tackle complex industry challenges and accelerate digital transformation. Together, we create impactful solutions that redefine standards and pave the way for sustainable growth and progress.
            
        </box>
    </box>

    </>
  );
}

export default template;