import React from 'react';
import { Typography,Box } from '@mui/material';
import ImgAWS from '../../../img/partnerships/awslogo.png';
import ImgHexnode from '../../../img/partnerships/hexnodelogo.png';
import ImgSAS from '../../../img/partnerships/sas.png';
import ImgExostar from '../../../img/partnerships/exostar.png';

import ImgRedirlogo  from '../../../img/partnerships/redirlogo.png';
import { Height, Padding } from '@mui/icons-material';


const template = () => {
    return (
      <>
      <box style={{
        //   border:"1px solid black", //For visualizing the outer box
          width:"100%",
          // height:'250px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
      }}>
          <box style={{
            //   border:"5px solid blue", //For visualizing the outer box
              // marginTop:"-70px",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap:"50px"
          }}>
              {/* For MedDRA box */}
              <box style={{
              borderRadius: "20px",
              border: '1px solid #707070',
              height:'180px',
              width:'230px',
              display: "flex", 
              alignItems: "center", 
              justifyContent: "center", 
              }}>
                  <box
                      style={{
                      height: "100px", // Box height
                      width: "100px",  // Box width
                      display: "flex", // Flexbox for centering
                      alignItems: "center", // Center vertically
                      justifyContent: "center", // Center horizontally
                      // border: "2px solid red", // Optional: Visualize the box
                  }}
  >
                          <img
                          src={`${ImgAWS}`} // 
                          alt="Partners"
                          style={{
                          maxWidth: "100%", // Scale image within the box
                          maxHeight: "100%", // Maintain aspect ratio
                          }}
                      />
                  </box>
                  <box style={{
                      height: "20px", // Box height
                      width: "20px",  // Box width
                      position:'relative',
                      // border: "2px solid red", // Optional: Visualize the box
                      bottom: '60px',
                      left:'30px'
                  }}>
                      <img src={`${ImgRedirlogo}`}
                      style={{
                          maxWidth:'100%',
                          maxHeight:'100%'
                      }}
                      
                      />
                  </box>
              </box>
              
              {/* For Uppsala box */}
              <box style={{
              borderRadius: "20px",
              border: '1px solid #707070',
              height:'180px',
              width:'230px',
              display: "flex", 
              alignItems: "center", 
              justifyContent: "center", 
              }}>
                  <box
                      style={{
                      height: "100px", // Box height
                      width: "100px",  // Box width
                      display: "flex", // Flexbox for centering
                      alignItems: "center", // Center vertically
                      justifyContent: "center", // Center horizontally
                      // border: "2px solid red", // Optional: Visualize the box
                  }}
  >
                          <img
                          src={`${ImgHexnode}`} 
                          alt="Partners"
                          style={{
                          maxWidth: "100%", // Scale image within the box
                          maxHeight: "100%", // Maintain aspect ratio
                          }}
                      />
                  </box>
                  <box style={{
                      height: "20px", // Box height
                      width: "20px",  // Box width
                      position:'relative',
                      // border: "2px solid red", // Optional: Visualize the box
                      bottom: '60px',
                      left:'30px'
                  }}>
                      <img src={`${ImgRedirlogo}`}
                      style={{
                          maxWidth:'100%',
                          maxHeight:'100%'
                      }}
                      
                      />
                  </box>
              </box>
              {/* For SCDM box */}
              <box style={{
              borderRadius: "20px",
              border: '1px solid #707070',
              height:'180px',
              width:'230px',
              display: "flex", 
              alignItems: "center", 
              justifyContent: "center", 
              }}>
                  <box
                      style={{
                      height: "100px", // Box height
                      width: "100px",  // Box width
                      display: "flex", // Flexbox for centering
                      alignItems: "center", // Center vertically
                      justifyContent: "center", // Center horizontally
                      // border: "2px solid red", // Optional: Visualize the box
                  }}
  >
                          <img
                          src={`${ImgSAS}`} // Replace with your image source
                          alt="Partners"
                          style={{
                          maxWidth: "100%", // Scale image within the box
                          maxHeight: "100%", // Maintain aspect ratio
                          }}
                      />
                  </box>
                  <box style={{
                      height: "20px", // Box height
                      width: "20px",  // Box width
                      position:'relative',
                      // border: "2px solid red", // Optional: Visualize the box
                      bottom: '60px',
                      left:'30px'
                  }}>
                      <img src={`${ImgRedirlogo}`}
                      style={{
                          maxWidth:'100%',
                          maxHeight:'100%'
                      }}
                      
                      />
                  </box>
              </box>
              {/* For CDISC box */}
              <box style={{
              borderRadius: "20px",
              border: '1px solid #707070',
              height:'180px',
              width:'230px',
              display: "flex", 
              alignItems: "center", 
              justifyContent: "center", 
              }}>
                  <box
                      style={{
                      height: "100px", // Box height
                      width: "100px",  // Box width
                      display: "flex", // Flexbox for centering
                      alignItems: "center", // Center vertically
                      justifyContent: "center", // Center horizontally
                      // border: "2px solid red", // Optional: Visualize the box
                  }}
  >
                          <img
                          src={`${ImgExostar}`} // Replace with your image source
                          alt="Partners"
                          style={{
                          maxWidth: "100%", // Scale image within the box
                          maxHeight: "100%", // Maintain aspect ratio
                          }}
                      />
                  </box>
                  <box style={{
                      height: "20px", // Box height
                      width: "20px",  // Box width
                      position:'relative',
                      // border: "2px solid red", // Optional: Visualize the box
                      bottom: '60px',
                      left:'30px'
                  }}>
                      <img src={`${ImgRedirlogo}`}
                      style={{
                          maxWidth:'100%',
                          maxHeight:'100%'
                      }}
                      
                      />
                  </box>
              </box>
             
          </box> 
      </box>
      </>
    );
  }
  
  export default template;