import React from 'react';
import { Box, Grid, Typography, Container } from '@mui/material';

const template = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="left"
      flexDirection="row"
      sx={{ py: {xs: 0, md: 1.5}, backgroundColor: '#042D43', pt: { xs: 0.5, md: 1 }, pb: { xs: 0.5, md: 1 } }}
    >
    {/* <Container> */}
    <Grid 
        container
        direction={{ xs: 'column', md: 'row' }} 
        spacing={{ xs: 12, md: 12 }}
        alignItems={{ xs: 'center', md: 'center' }}
        justifyContent={{ xs: 'left', md: 'left' }}
    >
    <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left'}}>
      <Typography 
        variant="h1" 
        sx={{ 
          color: '#0DA5E9', 
          mr: {xs: 1, md:2}, 
          fontWeight: 'light', 
          ml: {xs:0, md:4}, 
          fontSize:{xs:'14px', md: '44px'}, 
          whiteSpace: 'nowrap', 
          mb: {xs: -5, md: 2} , 
          mt: {xs: -6.5, md: 0} 
          }}>
        Unified Platform
      </Typography>
      <Box sx={{ borderLeft: '0.1px solid #FFFFFF', height: {xs: '20px', md: '60px'} }}></Box>
      <Typography variant="body2" sx={{ maxWidth: '1000px', color: '#FFFFFF', ml: {xs:1, md:2}, fontSize:{xs: '6px', md: '16px'}, lineHeight:{md: '24px', xs: '10px'} }}>
        An average site uses a minimum of 12 different electronic systems to collect and capture clinical study data.
        <br></br>CliniOps Conduct, Edge and Connect, built on a Unified platform provides a seamless experience for the study team. 
      </Typography>
    </Grid>
    </Grid>
    {/* </Container> */}
    </Box>
  );
};

export default template;


// import React from 'react';
// import { Box, Grid, Typography, Container } from '@mui/material';


// const template = () => {
//   return (
//     <Box
//       display="flex"
//       alignItems="center"
//       justifyContent="left"
//       flexDirection="row"
//       sx={{ py: 4, backgroundColor: '#042D43' }}
//     >
//     <Container>
//     <Grid 
//         container
//         direction="row"
//         spacing={12}
//         alignItems="center"
//         justifyContent="flex-start"
//     >
//     <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', flexWrap: 'nowrap' }}>
//       <Typography 
//         variant="h1"
//         sx={{ color: '#0DA5E9', fontWeight: 'light', mr: 2, whiteSpace: 'nowrap' }}
//       >
//         Unified Platform
//       </Typography>
//       <Box sx={{ borderLeft: '0.1px solid #FFFFFF', height: '20px', mr: 2 }}></Box>
//       <Typography variant="body2" sx={{ maxWidth: '800px', color: '#FFFFFF', textAlign: 'left' }}>
//         An average site uses a minimum of 12 different electronic systems to collect and capture clinical study data.
//         CliniOps Conduct, Edge, Connect and Insights, built on a Unified platform provides a seamless experience for the study team. 
//       </Typography>
//     </Grid>
//     </Grid>
//     </Container>
//     </Box>
//   );
// };

// export default template;

