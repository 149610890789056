// import React from "react";
// import { Box, Grid } from '@mui/material';

import Imgimage1 from '../../../../img/home/image2.png';

// const template = () => {
//   return (
//     <>
//       <Box
//         sx={{
//           pt: { xs: 15, md: 9 },
//           pb: { xs: 5, md: 3 },
//           width: '100%',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//         }}
//       >
//         <Grid container spacing={6} alignItems="center">
//           <Grid item xs={12}>
//             <img
//               src={`${Imgimage1}`}
//               alt="Unified Platform"
//               title="Unified Platform"
//               style={{ width: '100%', height: 'auto', display: 'block' }}
//             />
//           </Grid>
//         </Grid>
//       </Box>
//     </>
//   );
// };

// export default template;


import React from 'react';
import { Box, Divider } from '@mui/material';

// import Imgbackground from '../../../../img/product/_0/product-offerings.png';

const template = () => {
  return (
    <>
    <Box>
    <Box style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "50px 0 0 0",
      overflow: "hidden",
 
      }}>
        <img
          src={`${Imgimage1}`}
          alt="Unified Platform"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain" }}
        />
      </Box>
      </Box>
      <Divider/>
    </>
  );
}
 
export default template;