// import React from 'react';
// import { Typography } from '@mui/material';

// const template = () => {
//   return (
//     <>
//       <Typography variant="h1" component="h1">
//         Contact Us
//       </Typography>
//       <Typography variant="subtitle1" sx={{ mt: 1 }}>
//       Get in touch
//       </Typography>
//     </>
//   );
// }

// export default template;


import React from 'react';
import { Grid,Box } from '@mui/material';
import { teal } from '@mui/material/colors';

import contactBanner from '../../../../img/contact/contactBanner.png';


const template = () => {
  return (
    <>
    <Box>
    <Box style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "50px 0 0 0", 
      overflow: "hidden",

      }}>
  <img
    src={`${contactBanner}`}
    alt=""
    style={{ 
      maxWidth: "100%", 
      maxHeight: "100%", 
      objectFit: "contain" }}
  />
  </Box>
  {/* <Box sx={{
          position: 'absolute',
          top: { xs: '70px', md: '70px' },
          left: { xs: '10px', md: '20px' },
          right: 0,
          bottom: 0,
          display: 'flex',
          color: '042D43',
          textAlign: 'topleft',
          fontSize: { xs: '20px', sm: '40px', md: '50px' }, // Responsive font size
          fontWeight: 300,
          // fontFamily: 'Segoe UI',
        }}>
      Contact 
  </Box> */}
  </Box>

    </>
  );
}

export default template;