import React from 'react';
import { Box, Typography } from '@mui/material';
import ImgPartners from '../../../img/partnerships/partners.png';

const Template = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: 'auto',
        marginTop: '60px',
      }}
    >
      {/* Image */}
      <img
        src={ImgPartners}
        alt="Partners"
        style={{
          width: '100%',
          height: 'auto',
          display: 'block',
          objectFit: 'cover',
        }}
      />

      {/* Text Overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: { xs: '5px', sm: '15px', md: '20px' },
          left: { xs: '5px', sm: '15px', md: '20px' },
          right: 0,
          bottom: 0,
          display: 'flex',
          color: '#FFF',
          textAlign: 'topleft',
          fontSize: { xs: '15px', sm: '40px', md: '60px' }, // Responsive font size
          fontWeight: 300,
          fontFamily: 'Segoe UI',
        }}
      >
        Partnerships
      </Box>
    </Box>
  );
};

export default Template;
