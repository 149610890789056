import React from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';


const template = () => {
    return (
      <>
        <Box 
        sx={{ 
            display:'flex', 
            textAlign: {xs: "center", md: "left"}, 
            alignItems: "center", 
            justifyContent: 'center', 
            // marginBottom:3, 
            marginTop:3}}>
          <Typography variant="body1" sx={{ color: "#003B5C", mx:{xs:2, md: "", lg: 15}}}>
            CliniOps offers comprehensive data services to augment your in-house team, 
            or the services provided by your CRO partner, throughout study startup, study 
            conduct and study closeout phases. During study startup, our experienced configuration 
            team supports the study build including configuration of forms, edit checks, workflows, 
            reports, notifications et al., across all modules. A complete validation is also performed 
            with CRF completion guidelines and release notes. During the study conduct phase, clinical 
            data management, query resolution, user management and standard support services are provided. 
            In addition, data reconciliation, DB lock, data transfer, data archival and other services are 
            provided during the study closeout phase. Post that, CliniOps also provides, Biostatistics, 
            TLF programming, ADaM, SDTM, and medical writing services.
          </Typography>
        </Box>
      </>
    );
  }
  
  export default template;