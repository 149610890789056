import React from 'react';
import { Grid,Box } from '@mui/material';
// import { teal } from '@mui/material/colors';

import ImgCareer from '../../../../img/about/careers/career.png';


const template = () => {
  return (
    <>
    <Box>
    <Box style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "50px 0 0 0", 
      overflow: "hidden",

      }}>
  <img
    src={`${ImgCareer}`}
    alt=""
    style={{ 
      maxWidth: "100%", 
      maxHeight: "100%", 
      objectFit: "contain" }}
  />
  </Box>
  {/* <Box sx={{
          position: 'absolute',
          top: { xs: '70px', sm: '60px', md: '70px' },
          left: { xs: '5px', sm: '15px', md: '20px' },
          right: 0,
          bottom: 0,
          display: 'flex',
          color: '#042D43',
          textAlign: 'topleft',
          fontSize: { xs: '17px', sm: '40px', md: '45px' }, // Responsive font size
          fontWeight: 'light',
          // fontFamily: 'Segoe UI',
        }}>
      Careers 
  </Box> */}
  </Box>

    </>
  );
}

export default template;