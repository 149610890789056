import React from 'react';
import { Box, Grid, Typography, Container } from '@mui/material';

import ImgCliniOpsConduct from '../../../../img/product/conduct/CliniOpsConduct.png';

const template = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      sx={{ py: 4 }}
    >
    <Container>
    <Grid 
        container
        direction={{ xs: 'column', md: 'row' }} 
        spacing={{ xs: 4, md: 12 }}
        alignItems={{ xs: 'center', md: 'flex-start' }}
        justifyContent={{ xs: 'center', md: 'flex-start' }}
    >
    <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
      <img 
        src={ImgCliniOpsConduct} 
        className="co-image-product"
        alt="CliniOps Conduct" 
        style={{ width: '100%', maxWidth: '250px' }} 
      />
      </Grid>
      <Grid item xs={12} md={9}>
      <Typography variant="body1" sx={{ maxWidth: '1000px', color: '#003B5C', textAlign: { xs: 'center', md: 'left' }, py:1 }}>
        A unified platform to streamline clinical data management and clinical operations 
        and accelerate regulatory submission processes. Sponsors and CROs can view the 
        data along with powerful dashboards, reports, and study KPIs.
        <br></br>
        Conduct is a unified platform that streamlines clinical data management and clinical 
        operations and accelerates regulatory submission processes. It enables central and remote 
        monitoring (rSDV). Sponsors and CROs can view the data along with powerful dashboards, 
        reports, and study KPIs to support decentralized, virtual, or hybrid trials. The platform 
        also ensures the trial's overall alignment with the JTF Core Competency Framework, which is 
        globally utilized by biopharma industry stakeholders (sites, CROs, Sponsors, and professional organizations) 
        to improve the quality, accuracy, and safety of clinical research.  
      </Typography>
      </Grid>
      </Grid>
      </Container>
    </Box>
  );
};

export default template;
