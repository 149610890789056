import React from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';


const template = () => {
    return (
      <>
        <Box 
        sx={{ 
            display:'flex', 
            textAlign: "center", 
            alignItems: "center", 
            justifyContent: 'center', 
            // marginBottom:3, 
            marginTop:3}}>
          <Box variant="body2" sx={{ color: "#042d43", mx:{xs:2, md: "", lg: 15}}}>
          CliniOps offers a configurable and scalable Unified Platform for Reimagined 
          Clinical Trials. It is a comprehensive solution to also support Hybrid and Decentralized Clinical Trials 
          (Hybrid DCT) powered by real-time interactions such as Integrated Telemedicine.
          </Box>
        </Box>
      </>
    );
  }
  
  export default template;