import React from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';


const template = () => {
    return (
      <>
        <Box 
        sx={{ 
            display:'flex', 
            textAlign: {xs: "center", md: "left"}, 
            alignItems: "center", 
            justifyContent: 'center', 
            // marginBottom:3, 
            marginTop:3}}>
          <Typography variant="body1" sx={{ color: "#003B5C", mx:{xs:2, md: "", lg: 15}}}>
          CliniOps offers comprehensive clinical data management, biostatistics and medical writing services. Our data management team handles creation of Data Management Plan (DMP) query management, data review, cleaning and analysis, ensuring compliance with regulatory standards and study protocols. Client is also in complete control with continuous access to data with constant support from CliniOps data managers. 
          <br></br><br></br>
          Our experienced and knowledgeable data science team offer high-quality, cost-effective, best in class biostatistics services. We have rich experience in biostatistics analysis & reporting. Our biostatistics services offer you accurate data analysis and the ability to comply with regulatory requirements. Our services include Randomization, Statistical Analysis Plan (SAP), annotation of CRFs, Statistical Programming, Tables, Listings and Figures (TLFs), ADaM/SDTM and Define.xml generation. Our deep regulatory knowledge & domain expertise in biostatistics services helps you to navigate the complexities of study design, calculation of sample sizes, study randomization, Data Visualization etc.
          </Typography>
        </Box>
      </>
    );
  }
  
  export default template;