import React from 'react';
import { Grid,Box,Typography } from '@mui/material';
// import { teal } from '@mui/material/colors';
import InNews from '../../../../img/media/newsroom/Renew/InNews1.png';
const template = () => {
  return (
    <>
    <Box>
    <Box style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "50px 0 0 0", 
      overflow: "hidden",
      }}>
  <img
    src={`${InNews}`}
    alt=""
    style={{ 
      maxWidth: "100%", 
      maxHeight: "100%", 
       aspectRatio: '3',
      objectFit: "contain" }}
  />
  </Box>
  {/* <Box
            sx={{
              position: 'absolute',
              top: '15%',
              left: '5%',
              transform: 'translate(-15%, -20%)',
              zIndex: 1,
              textAlign: 'center',
            }}
          >
  <Typography
            sx={{
              color: 'white',
              fontSize: { xs: '2rem', sm: '3rem', md: '3rem' },
              // fontFamily: 'Segoe UI',
              fontWeight: '280',
              lineHeight: '1.2',
              wordWrap: 'break-word',
            }}
          >
            Newsroom
          </Typography>
          </Box> */}
  </Box>
    </>
  );
}
export default template;