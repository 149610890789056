import React from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';


const template = () => {
    return (
      <>
        <Box 
        sx={{ 
            display:'flex', 
            textAlign: {xs: "center", md: "left"}, 
            alignItems: "center", 
            justifyContent: 'center', 
            // marginBottom:3, 
            marginTop:3}}>
          <Typography variant="body1" sx={{ color: "#003B5C", mx:{xs:2, md: "", lg: 15}}}>
          During the study setup phase, our experienced configuration team supports the study 
          build including form development, form configuration, finalizing requirements specification 
          including but not limited to edit checks, workflows, dashboards, reports, alerts, notifications 
          across all modules. A complete validation is also performed with CRF completion guidelines and release notes.
          </Typography>
        </Box>
      </>
    );
  }
  
  export default template;