import React from 'react';
import {Grid, Typography,Box } from '@mui/material';
import ImgTopwave from '../../../img/partnerships/topwave.png';
import ImgBottomwave from '../../../img/partnerships/bottomwave.png';
import { BorderAllOutlined } from '../../../../node_modules/@mui/icons-material/index';
import { colors } from '../../../../node_modules/@mui/material/index';



const template = () => {
  return (
    <>
<box style={{display: 'flex',flexDirection: 'column',}}>
<box style={{width: '100%', height: '513px',marginTop:'20px'}}>
  <img
    src={`${ImgTopwave}`}
    alt="Topwave"
    style={{
      width: '100%',
      display: 'block',
      objectFit: 'cover',
      height:'100%',
      
    }}
  />
</box >
<box style={{position:'relative',}}>
<img
    src={`${ImgBottomwave}`}
    alt="Bottomwave"
    style={{
        width: '100%',
        display: 'block',
        objectFit: 'cover',
        height:'956',
        marginTop:'-530px'
    }}
  />
<box style={{
    // border: "1px solid red", //for visualizing the box
    display:'block',
    position: 'absolute', // Position this box over the image
      top: '50%', // Center vertically on the image
      left: '50%', // Center horizontally on the image
      transform: 'translate(-50%, -210%)', // Adjust for perfect centering
      // border: '1px solid red', //for visualizing the box
      padding: '10px',
      textAlign: 'center',
      width:'55%'
    }}>
  <box>
      <Typography style={{
        // border: "1px solid red", //for visualizing the box
  color:"#07A7F9",
  fontWeight: 400,
  fontFamily: 'Segoe UI',
  fontSize: '25px',
  textAlign:"left"
  }}> 
  Let's build something great together!
      </Typography>
  </box>
  <box>
  <Typography style={{
    color:"#042D43",
    fontWeight: 400,
    fontFamily: 'Segoe UI',
    fontSize: '15px',
    lineHeight:"22px",
    textAlign:"justify",
    // border:"1px solid red"
  }}>
  At CliniOps, we are committed to building meaningful and long-term partnerships with organizations that share our passion for innovation and growth. We believe collaboration is key to unlocking new opportunities and delivering exceptional value to our customers. We always seek to build strong, mutually beneficial relationships with organizations that share our vision and commitment to excellence.
  </Typography>
  </box>
</box>
<box style={{
    // border: "1px solid red", //for visualizing the box
    display:'block',
    position: 'absolute', // Position this box over the image
      // top: '50%', // Center vertically on the image
      bottom:'20px',
      left: '50%', // Center horizontally on the image
      transform: 'translate(-50%, -70%)', // Adjust for perfect centering
      padding: '10px',
      textAlign: 'center',
    }}>

<Typography style={{
    color:"#FFFFFF",
    fontWeight: 400,
    fontFamily: 'Segoe UI',
    fontSize: '60px',
    // lineHeight: 'no',
    textAlign:"justify"
}}>
  Technology Partners
</Typography>
</box>
</box>
<box style={{ 
  // border: "1px solid red",
  height: '150px', 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center' }}>
  <box style={{ width: "70%" }}>
    <Typography style={{
      color: "#042D43",
      fontWeight: 400,
      fontFamily: 'Segoe UI',
      fontSize: '15px',
      lineHeight: '24px',
      textAlign: "justify"
    }}>
      Our technology ecosystem partners are key collaborators in driving innovation and creating powerful solutions that transform industries. We integrate their expertise with our cutting-edge platforms to deliver seamless, scalable technologies that address complex challenges. Together, we empower businesses to unlock new opportunities, enhance efficiency, and accelerate growth in an increasingly digital world.
    </Typography>
  </box>
</box>
</box>
    </>
  );
}

export default template;