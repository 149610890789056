import React from 'react';
import { Grid, Typography, Button, Collapse, Box } from "@mui/material";

const CustomProfile = ({
    ImgSrc,
    Name,
    Position,
    Summary,
    FullContent
}) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = (event) => {
        setExpanded(!expanded);
        event.target.innerText = expanded ? 'Know More' : 'Show Less';
    };

    return (
        <Box
            sx={{
                backgroundColor: 'white',
                borderRadius: '16px',
                padding: '24px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                marginBottom: '16px', // Space between profiles
            }}
        >
            <Grid container direction="row" spacing={{ xs: 2, md: 6 }}>
                <Grid item xs={12} md={2}>
                    <img
                        src={ImgSrc}
                        className="co-image"
                        alt={Name}
                        title={Name}
                        style={{ maxHeight: '152px' }}
                    />
                </Grid>
                <Grid item xs={12} md={10}>
                    <Typography variant="h3" component="h2" className="co-page-heading">
                        {Name}{Position}
                    </Typography>
                    <Typography sx={{ mt: 1 }}>
                        {Summary}
                    </Typography>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        {FullContent}
                    </Collapse>
                    <Button
                        variant="contained"
                        style={{ background: '#042D43' }}
                        onClick={handleExpandClick}
                        sx={{ mt: 1, ml: -1 }}
                    >
                        Know More
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CustomProfile;
